<template>
  <div class="body container">
    <Breadcrumb />
    <h1>{{ this.pageTitle }}</h1>
    <Form
      v-if="workMonetaryStatement"
      :workMonetaryStatement="workMonetaryStatement"
      @onSubmit="update"
    />
  </div>
</template>

<script>
import "@/assets/scss/style.scss";
import Breadcrumb from "@/components/Breadcrumb";
import Form from "@/views/monetaryStatement/Form";
import monetaryStatementApi from "@/apis/monetaryStatement";
import formMixin from "@/mixins/form";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  mixins: [formMixin],
  components: {
    Breadcrumb,
    Form
  },
  computed: {
    pageTitle() {
      return this.$t("pageTitle.editMonetaryStatement");
    },
    workMonetaryStatement() {
      if (!this.monetaryStatement) return null;
      return {
        category: this.monetaryStatement.category,
        item_name: this.monetaryStatement.item_name,
        target_name: this.monetaryStatement.target_name,
        target_id: Number(this.monetaryStatement.target_id),
        transact_at: this.monetaryStatement.transact_at,
        price: Number(this.monetaryStatement.price),
        currency: this.monetaryStatement.currency
      };
    }
  },
  data() {
    return {
      monetaryStatement: null
    };
  },
  async created() {
    const statementId = this.$route.params.id;
    const { transaction_statement } = await monetaryStatementApi.fetchMonetaryStatement(
      statementId
    );
    this.monetaryStatement = transaction_statement;
  },
  methods: {
    async update(monetaryStatement) {
      try {
        await monetaryStatementApi.updateMonetaryStatement(
          this.$route.params.id,
          monetaryStatement
        );
        this.$router.push({ name: "monetaryStatement" });
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>
