import ApiService from "@/common/api.service";

export default {
  fetchMonetaryStatements(params) {
    return ApiService.query("transaction-statements", params);
  },
  fetchTransaction(params) {
    return ApiService.query("transactions", params);
  },
  fetchReceivableAndPayable(params) {
    return ApiService.query("account-payables-and-receivables", params);
  },
  // fetchAccountPayable(params) {
  //   return ApiService.query("account-payables", params);
  // },
  fetchMonetaryStatement(monetaryStatementId) {
    return ApiService.query(`transaction-statement/${monetaryStatementId}`);
  },
  createMonetaryStatement(monetaryStatement) {
    return ApiService.post("transaction-statement", monetaryStatement);
  },
  updateMonetaryStatement(monetaryStatementId, monetaryStatement) {
    return ApiService.patch(
      `transaction-statement/${monetaryStatementId}`,
      monetaryStatement
    );
  },
  deleteMonetaryStatement(monetaryStatementId) {
    return ApiService.delete(`transaction-statement/${monetaryStatementId}`);
  }
};
