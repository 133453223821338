<template>
  <el-form
    ref="form"
    :model="monetaryStatement"
    @submit.prevent.native="submit"
    label-position="right"
    label-width="180px"
  >
    <el-form-item label="收支類型" prop="category">
      <el-radio-group
        style="width: 100%"
        :value="monetaryStatement.category"
        @input="category => (monetaryStatement.category = category)"
      >
        <el-radio label="income">收入</el-radio>
        <el-radio label="expense">支出</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item
      :label="`${categoryTypeText}日期`"
      :rules="[commonRules.input]"
      prop="transact_at"
    >
      <el-date-picker
        v-model="monetaryStatement.transact_at"
        type="date"
        value-format="yyyy-MM-dd"
      />
    </el-form-item>
    <el-form-item label="要選系統裡的使用者嗎？">
      <el-radio-group
        style="width: 100%"
        :value="targetIsUser"
        @input="newTargetIsUser => (targetIsUser = newTargetIsUser)"
      >
        <el-radio :label="true">對，我要直接選</el-radio>
        <el-radio :label="false">不，我要自己輸入</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item
      v-if="targetIsUser"
      :label="`${categoryTypeText}來源`"
      :rules="[commonRules.input]"
      prop="target_id"
    >
      <el-select
        style="width: 100%"
        v-model="monetaryStatement.target_id"
        placeholder="請輸入使用者名稱/Email 查詢使用者"
        clearable
        filterable
        remote
        :remote-method="fetchUsers"
      >
        <el-option
          v-for="user in users"
          :key="user.id"
          :label="`${userMethods.displayName(user.first_name, user.last_name)} (${user.email})`"
          :value="user.id"
        />
      </el-select>
    </el-form-item>
    <el-form-item
      v-else
      :label="`${categoryTypeText}來源`"
      :rules="[commonRules.input]"
      prop="target_name"
    >
      <el-input v-model="monetaryStatement.target_name" required />
    </el-form-item>
    <el-form-item
      :label="`${categoryTypeText}項目名稱`"
      :rules="[commonRules.input]"
      prop="item_name"
    >
      <el-input v-model="monetaryStatement.item_name" required />
    </el-form-item>
    <el-form-item
      required
      label="金額"
      :rules="[commonRules.money]"
      prop="price"
    >
      <InputMoney
        :money="monetaryStatement.price"
        :currency="monetaryStatement.currency"
        @changeMoney="
          price => {
            monetaryStatement.price = price;
          }
        "
        @changeCurrency="
          currency => {
            monetaryStatement.currency = currency;
          }
        "
      />
    </el-form-item>
    <el-row>
      <el-col :span="24" align="center">
        <el-button @click="backToPreviousPage">
          回上一頁
        </el-button>
        <el-button type="primary" @click="onSubmit">
          {{ $t("button.save") }}
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import "@/assets/scss/style.scss";
import { user } from "@ivy-way/material";
import { InputMoney } from "@/components/input";
import formMixin from "@/mixins/form";
import usersApi from "@/apis/users";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  props: ["workMonetaryStatement"],
  mixins: [formMixin],
  components: {
    InputMoney
  },
  data() {
    return {
      monetaryStatement: {
        category: "income",
        item_name: "",
        target_name: "",
        transact_at: null,
        target_id: null,
        price: 0,
        currency: "TWD"
      },
      targetIsUser: true,
      users: []
    };
  },
  computed: {
    pageTitle() {
      return this.$t("pageTitle.createMonetaryStatement");
    },
    userMethods() {
      return user;
    },
    categoryTypeText() {
      return this.monetaryStatement.category === "income" ? "收入" : "支出";
    }
  },
  watch: {
    targetIsUser() {
      if (!this.targetIsUser) {
        this.monetaryStatement.target_id = null;
      }
    }
  },
  mounted() {
    if (this.workMonetaryStatement) {
      this.monetaryStatement = this.workMonetaryStatement;
    }
    if (this.monetaryStatement.target_id) {
      this.targetIsUser = true;
      this.fetchUsers(this.monetaryStatement.target_name);
    } else {
      this.targetIsUser = false;
    }
  },
  methods: {
    backToPreviousPage() {
      this.$router.go(-1);
    },
    async fetchUsers(keyword) {
      const {
        users: { data }
      } = await usersApi.getUsers({ q: keyword });
      this.users = data;
    },
    async onSubmit() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        this.$message.error(this.$t("message.form_validation_error"));
        return;
      }
      let submitMonetaryStatement = this.monetaryStatement;
      if (!this.targetIsUser) {
        const NONE_TARGET_ID = 0;
        submitMonetaryStatement.target_id = NONE_TARGET_ID;
      }
      this.$emit("onSubmit", submitMonetaryStatement);
    }
  }
};
</script>
